
import { defineComponent, ref, watch } from 'vue';
import TemplateSelector from '@/components/global-config/shared/TemplateSelector.vue';
import { DefaultTemplateSetting } from '@/api/dto/global-config.dto';
import { FetchStates } from '@/helpers/fetch-states';
import { ElMessage } from 'element-plus';
import { setGlobalConfig, getGlobalConfig } from '@/api/accidentplan-api';

const SETTING_KEY = 'DEFAULT_RR_TEMPLATE_ID_SETTING';

export default defineComponent({
  components: { TemplateSelector },
  setup() {
    const setting = ref<DefaultTemplateSetting>({
      default_template_id: 0,
    });

    const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);

    // get the initial setting from the server
    getGlobalConfig<DefaultTemplateSetting>(SETTING_KEY).then(resp => {
      // set our local "setting" object
      setting.value = resp.value;
      fetchState.value = FetchStates.FETCHED;

      // watch if the user changes the value
      watch(
        () => setting.value.default_template_id,
        async newSetting => {
          fetchState.value = FetchStates.FETCHING;

          // set the setting on the server
          const newlySetSetting = await setGlobalConfig(
            SETTING_KEY,
            setting.value,
          );
          fetchState.value = FetchStates.FETCHED;

          ElMessage.success('Successfully changed Default Template');
        },
      );
    });

    return { setting, fetchState, FetchStates };
  },
});
