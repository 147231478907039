
import { defineComponent, PropType, ref, watch } from 'vue';
import { DefaultWorkflowObject } from '@/api/dto/global-config.dto';

export default defineComponent({
  emit: ['update:modelValue'],
  props: {
    modelValue: Object as PropType<DefaultWorkflowObject>,
  },
  setup(props, context) {
    const workflow = ref<DefaultWorkflowObject>({
      ...props.modelValue!,
      code_block: JSON.stringify(props.modelValue!.code_block),
    });

    const setWorkflow = (newVal?: DefaultWorkflowObject) => {
      if (!newVal) return;
      workflow.value = {
        ...newVal,
        code_block: JSON.stringify(newVal.code_block),
      };
    };

    const returnWorkflow: () => DefaultWorkflowObject = () => ({
      ...workflow.value,
      code_block: JSON.parse(workflow.value.code_block as string),
    });

    watch(() => props.modelValue, setWorkflow);

    const onWorkflowChanged = () =>
      context.emit('update:modelValue', returnWorkflow());

    return { workflow, onWorkflowChanged };
  },
});
