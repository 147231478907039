import { searchRRTemplates } from '@/api/accidentplan-api';
import { RRTemplate } from '@/api/dto/rr-template.dto';
import { alertError } from '@/helpers/alert-error';
import { FetchStates } from '@/helpers/fetch-states';
import { ref } from 'vue';

export function useTemplateList() {
  const templateFetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const templateList = ref<RRTemplate[]>([]);

  const fetchTemplateList = async () => {
    templateFetchState.value = FetchStates.FETCHING;

    try {
      const allTemplates = await searchRRTemplates({});

      templateList.value = allTemplates;

      templateFetchState.value = FetchStates.FETCHED;
    } catch (error) {
      console.error(error);
      alertError(error, 'Failed to fetch roadready templates.');

      templateFetchState.value = FetchStates.UNFETCHED;
    }
  };

  fetchTemplateList();

  return {
    templateFetchState,
    templateList,
    fetchTemplateList,
  };
}
