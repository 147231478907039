import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock("div", null, [
    _withDirectives(_createVNode(_component_el_select, {
      modelValue: _ctx.template,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.template = $event)),
      placeholder: "Select Template",
      filterable: "",
      onChange: _ctx.onSelectionChanged
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.templateList, (template) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: template.id,
            label: template.name,
            value: template.id
          }, null, 8, ["label", "value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue", "onChange"]), [
      [_directive_loading, _ctx.templateFetchState !== _ctx.FetchStates.FETCHED]
    ])
  ]))
}