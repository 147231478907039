import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")

  return (_ctx.workflow)
    ? (_openBlock(), _createBlock(_component_el_form, {
        key: 0,
        model: _ctx.workflow
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Title" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "text",
                modelValue: _ctx.workflow.title,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.workflow.title = $event)),
                onChange: _ctx.onWorkflowChanged
              }, null, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Event" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "text",
                modelValue: _ctx.workflow.event,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.workflow.event = $event)),
                onChange: _ctx.onWorkflowChanged
              }, null, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, { label: "Code Block" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                modelValue: _ctx.workflow.code_block,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.workflow.code_block = $event)),
                onChange: _ctx.onWorkflowChanged
              }, null, 8, ["modelValue", "onChange"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model"]))
    : _createCommentVNode("", true)
}