
import { defineComponent, ref, watch } from 'vue';
import { useTemplateList } from '@/composables/useTemplateList';
import { FetchStates } from '@/helpers/fetch-states';

export default defineComponent({
  props: ['modelValue'],
  emits: ['update:modelValue'],
  setup(props, context) {
    const { templateList, templateFetchState } = useTemplateList();

    const template = ref<number>(props.modelValue);

    const onSelectionChanged = (value: any) =>
      context.emit('update:modelValue', value);

    watch(
      () => props.modelValue,
      newProp => {
        template.value = newProp;
      },
    );

    return {
      templateList,
      templateFetchState,
      template,
      onSelectionChanged,
      FetchStates,
    };
  },
});
