
import { defineComponent, PropType, ref, watch, computed } from 'vue';
import { DefaultWorkflowObject } from '@/api/dto/global-config.dto';
import WorkflowBuilder from '@/components/global-config/shared/WorkflowBuilder.vue';
import { ElMessageBox } from 'element-plus';
import { CodeBlockDTO } from '@/api/dto/workflow.dto';

export default defineComponent({
  components: { WorkflowBuilder },
  props: {
    modelValue: Array as PropType<DefaultWorkflowObject[]>,
  },
  emits: ['update:modelValue', 'changed'],
  setup(props, context) {
    const workflows = ref<DefaultWorkflowObject[]>(props.modelValue ?? []);
    const isEditorVisible = ref(false);
    const activeWorkflow = ref<DefaultWorkflowObject>();
    const activeWorkflowInd = ref<number>();
    const isActiveWorkflowNew = computed(
      () => activeWorkflowInd.value === undefined,
    );

    const emitUpdates = () => {
      context.emit('update:modelValue', workflows.value);
      context.emit('changed', workflows.value);
    };

    const showEditor = (workflow: DefaultWorkflowObject, ind?: number) => {
      isEditorVisible.value = true;
      activeWorkflowInd.value = ind;
      activeWorkflow.value = { ...workflow };
    };

    const newWorkflow = () => {
      const newWorkflow: DefaultWorkflowObject = {
        title: '',
        event: '',
        code_block: {
          _type: 'code-block',
          _config: {
            _actions: [
              {
                _type: 'print-message',
                _config: {
                  message: {
                    _type: 'constant-value',
                    _config: { value: 'Hello world!' },
                  },
                },
              },
            ],
          },
        },
      };

      showEditor(newWorkflow);
    };

    const deleteWorkflow = async (workflowInd: number) => {
      // TODO: add confirmation

      try {
        await ElMessageBox.confirm(
          `Are you sure you want to delete "${workflows.value[workflowInd].title}"?`,
          'Delete workflow?',
          { confirmButtonText: 'Yes', cancelButtonText: 'No' },
        );
      } catch (error) {
        return;
      }

      workflows.value.splice(workflowInd, 1);
      emitUpdates();
    };

    const closeEditor = () => (isEditorVisible.value = false);

    const onEditorClose = () => {
      activeWorkflowInd.value = undefined;
    };

    const saveWorkflow = () => {
      if (isActiveWorkflowNew.value)
        workflows.value.push(activeWorkflow.value!);
      else workflows.value[activeWorkflowInd.value!] = activeWorkflow.value!;

      emitUpdates();
      closeEditor();
    };

    watch(
      () => props.modelValue,
      newValue => (workflows.value = newValue!),
    );

    return {
      workflows,
      activeWorkflow,
      isActiveWorkflowNew,
      isEditorVisible,
      showEditor,
      newWorkflow,
      deleteWorkflow,
      onEditorClose,
      closeEditor,
      saveWorkflow,
    };
  },
});
