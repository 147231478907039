
import { defineComponent, ref, watch } from 'vue';
import WorkflowArray from '@/components/global-config/shared/WorkflowArray.vue';
import {
  DefaultWorkflowObject,
  DefaultWorkflowSetting,
} from '@/api/dto/global-config.dto';
import { getGlobalConfig, setGlobalConfig } from '@/api/accidentplan-api';
import { FetchStates } from '@/helpers/fetch-states';
import { alertError } from '@/helpers/alert-error';
import { ElMessage } from 'element-plus';

const SETTING_KEY = 'DEFAULT_WORKFLOWS_SETTING';

export default defineComponent({
  components: { WorkflowArray },
  setup() {
    const workflows = ref<DefaultWorkflowObject[]>([]);
    let oldWorkflows: DefaultWorkflowObject[] = workflows.value;
    const fetchState = ref(FetchStates.UNFETCHED);

    getGlobalConfig<DefaultWorkflowSetting>(SETTING_KEY).then(setting => {
      workflows.value = setting.value.workflows;
      oldWorkflows = [...workflows.value];
      fetchState.value = FetchStates.FETCHED;
    });

    const onWorkflowsChanged = async (
      newWorkflows: DefaultWorkflowObject[],
    ) => {
      fetchState.value = FetchStates.FETCHING;
      try {
        await setGlobalConfig<DefaultWorkflowSetting>(SETTING_KEY, {
          workflows: newWorkflows,
        });
      } catch (error) {
        workflows.value = oldWorkflows;
        alertError(error, 'There was an error updating the default workflows.');
        return;
      } finally {
        fetchState.value = FetchStates.FETCHED;
        oldWorkflows = [...newWorkflows];
      }

      ElMessage.success('Successfully updated default workflows.');
    };

    return { workflows, onWorkflowsChanged, fetchState, FetchStates };
  },
});
